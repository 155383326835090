import React, { lazy } from 'react';

import Loadable from 'components/Loadable';
import AuthLayout from '../layout/auth/AuthLayout';
import PermissionDenied from '../pages/authentication/PermissionDenied';

const Login = Loadable(lazy(() => import('pages/authentication/Login')));
const Signup = Loadable(lazy(() => import('pages/authentication/Signup')));

const AuthRoutes = {
    element: <AuthLayout />,
    children: [
        {
            path: 'login',
            element: <Login />,
        },
        {
            path: 'signup',
            element: <Signup />,
        },
        {
            path: 'permissionDenied',
            element: <PermissionDenied />,
        },
    ],
};

export default AuthRoutes;
