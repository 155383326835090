import React, { lazy } from 'react';

import Loadable from 'components/Loadable';
import AppLayout from 'layout/app/AppLayout';
import ProtectedRoute from './ProtectedRoute';
import AuthRedirect from '../layout/app/AuthRedirect';
import { Outlet } from 'react-router-dom';

const Whiskeys = Loadable(lazy(() => import('pages/admin/Whiskeys')));
const Metrics = Loadable(lazy(() => import('pages/admin/Metrics')));
const Badges = Loadable(lazy(() => import('pages/admin/Badges')));

const AppRoutes = {
    element: <AuthRedirect />,
    children: [
        {
            path: '/',
            element: <AppLayout />,
            children: [
                {
                    element: <ProtectedRoute roles={['admin']} fallbackRoute={'/permissionDenied'} />,
                    children: [
                        {
                            path: 'admin',
                            element: <Outlet />,
                            children: [
                                {
                                    path: 'whiskeys',
                                    element: <Whiskeys />,
                                },
                                {
                                    path: 'metrics',
                                    element: <Metrics />,
                                },
                                {
                                    path: 'badges',
                                    element: <Badges />,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
};

export default AppRoutes;
