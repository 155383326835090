import React from 'react';
import LegalLayout from '../layout/legal/LegalLayout';
import PrivacyPolicy from '../pages/legal/PrivacyPolicy';
import TermsAndConditions from '../pages/legal/TermsAndConditions';

const LoginRoutes = {
    element: <LegalLayout />,
    children: [
        {
            path: 'privacy',
            element: <PrivacyPolicy />,
        },
        {
            path: 'terms',
            element: <TermsAndConditions />,
        },
    ],
};

export default LoginRoutes;
