import * as React from 'react';
import { useEffect, useState } from 'react';
import { getTermsAndConditions } from '../../lib/firebase/legalService';

const PrivacyPolicy = () => {
    const [termsAndConditions, setTermsAndConditions] = useState(null);

    useEffect(() => {
        getTermsAndConditions().then((tc) => {
            setTermsAndConditions(tc);
        });
    }, []);

    useEffect(() => {
        if (!termsAndConditions) return;

        document.getElementById('terms-and-conditions').innerHTML = termsAndConditions.body;
    }, [termsAndConditions]);

    return <div className={'p-4'}>{termsAndConditions != null && <div id={'terms-and-conditions'} />}</div>;
};

export default PrivacyPolicy;
