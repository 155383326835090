import React from 'react';
import AuthRoutes from './AuthRoutes';
import AppRoutes from './AppRoutes';
import LegalRoutes from './LegalRoutes';
import RootLayout from '../layout/RootLayout';
import ErrorBoundary from './ErrorBoundary';

export const routes = [
    {
        element: <RootLayout />,
        errorElement: <ErrorBoundary />,
        children: [
            {
                children: [AuthRoutes, LegalRoutes, AppRoutes],
            },
        ],
    },
];
