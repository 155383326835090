import * as React from 'react';
import { useEffect, useState } from 'react';
import { getPrivacyPolicy } from '../../lib/firebase/legalService';

const PrivacyPolicy = () => {
    const [privacyPolicy, setPrivacyPolicy] = useState(null);

    useEffect(() => {
        getPrivacyPolicy()
            .then((pp) => {
                setPrivacyPolicy(pp);
            })
            .finally(() => {});
    }, []);

    useEffect(() => {
        if (!privacyPolicy) return;

        document.getElementById('privacy-policy').innerHTML = privacyPolicy.body;
    }, [privacyPolicy]);

    return <div className={'p-4'}>{privacyPolicy != null && <div id={'privacy-policy'} />}</div>;
};

export default PrivacyPolicy;
