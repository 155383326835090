import React from 'react';
import { Outlet } from 'react-router-dom';
import { Toaster } from '../components/ui/Sonner';
import { ThemeProvider, ThemeContext } from '../contexts/ThemeContext';
import { UserProvider } from '../contexts/UserContext';

const RootLayout = () => {
    return (
        <UserProvider>
            <ThemeProvider>
                <ThemeContext.Consumer>
                    {({ theme }) => (
                        <div className={`${theme}`}>
                            <Outlet />
                            <Toaster />
                        </div>
                    )}
                </ThemeContext.Consumer>
            </ThemeProvider>
        </UserProvider>
    );
};

export default RootLayout;
